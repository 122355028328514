import { MasonryDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
} from 'common/constants/masonry';
import { RefillScoreCard } from 'components/refill-score/renderers/refill-score-card.renderer';
import { LearnMoreHeader } from 'components/masonry-renderers/learn-more-header.component';
import { RefillScoreLearnMoreDescription } from 'components/refill-score/refill-score-learn-more-definition.component';

export const initRefillScoreRegistry = () => {
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.SIMPLE_BADGE_CARD,
    RefillScoreCard,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.REFILL_SCORE,
    MASONRY_WIDGET_TYPES.AUTHORIZATION.LEARN_MORE.HEADER,
    LearnMoreHeader,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.DEFAULT,
    MASONRY_WIDGET_TYPES.LEARN_MORE_DEFINITION,
    RefillScoreLearnMoreDescription,
  );
};
