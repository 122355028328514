import type { ReactNode } from 'react';
import { Popover } from '@leagueplatform/genesis-core';

type PopoverContentProps = {
  children: ReactNode;
  menuWidth?: number;
  isOpen: boolean;
};

export const PopoverContent = ({
  children,
  menuWidth,
  isOpen,
}: PopoverContentProps) => (
  <Popover.Content
    showCloseButton={false}
    align="end"
    alignOffset={2}
    avoidCollisions
    side="bottom"
    css={{
      maxHeight: 300,
      width: menuWidth,
      overflowY: 'auto',
      borderRadius: '$small',
      ...(!isOpen && { display: 'none' }),
    }}
  >
    {children}
  </Popover.Content>
);
