import {
  useFormContext,
  useController,
  type UseControllerProps,
} from '@leagueplatform/web-common';
import { RHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';
import { useRequiredErrorMessage } from 'hooks/use-required-error-message.hook';
import { ariaDescribedbyProp } from 'common/utils/get-ariadescribedby-prop';
import {
  AutocompleteInput,
  type AutocompleteInputProps,
} from './autocomplete-input/autocomplete-input.component';

type RHFAutocompleteInputProps = Omit<
  AutocompleteInputProps,
  'hasError' | 'onInputValueChange' | 'value'
> & {
  id: string;
  hint?: string;
  onInputValueChange?: (value: string) => void; // AutocompleteInputProps have it as required
  validationRules?: UseControllerProps['rules'];
};

export const RHFAutocompleteInput = ({
  id,
  label,
  options,
  hint,
  required,
  onSelectedItemChange,
  onInputValueChange,
  validationRules,
  ...restProps
}: RHFAutocompleteInputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const requiredErrorMessage = useRequiredErrorMessage(label);

  const {
    field: { onChange, value },
  } = useController({
    name: id,
    control,
    rules: { required: required && requiredErrorMessage, ...validationRules },
  });

  return (
    <RHFInputWrapper id={id} hint={hint} required={required}>
      <AutocompleteInput
        options={options}
        value={value}
        onInputValueChange={(inputValue) => {
          onChange(inputValue);
          if (onInputValueChange) {
            onInputValueChange(inputValue);
          }
        }}
        onSelectedItemChange={onSelectedItemChange}
        hasError={!!errors?.[id]?.message}
        label={label}
        required={required}
        {...ariaDescribedbyProp({
          id,
          error: !!errors?.[id]?.message,
          hint,
        })}
        {...restProps}
      />
    </RHFInputWrapper>
  );
};
