import React, { useEffect, useState } from 'react';
import {
  Button,
  HeadingText,
  ParagraphText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { FormProvider, useForm } from '@leagueplatform/web-common';
import { captureAppError } from '@leagueplatform/observability';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';

import { useIntl } from '@leagueplatform/locales';
import { BasePage } from 'components/base-page.component';
import { ProfilePictureForm } from 'components/forms/profile-picture-form.component';
import { ACCOUNT_SETUP_CONTENT_MAX_WIDTH } from 'common/constants/ui';
import { TextCommunicationTermsModal } from 'components/modals/text-communication-terms/text-communication-terms.component';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  trackAnalyticsEvent,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { RHFTextInput } from 'components/form-elements/rhf-text-input.component';
import {
  emailValidation,
  phoneNumberValidation,
} from 'common/utils/rhf-form-validation.util';
import { useAccountSetupFormDefaultValues } from 'hooks/use-account-setup-form-default-values/use-account-setup-form-default-values.hook';
import { useUserName } from 'hooks/user-user-name/use-user-name.hook';
import { RHFCheckbox } from 'components/form-elements/rhf-checkbox.component';
import {
  AccountSetupInputs,
  useUpdateAccountSetup,
} from 'hooks/use-update-account-setup/use-update-account-setup.hook';
import { SENTRY_CONSTANTS } from 'common/constants/constants';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

export const AccountSetupPage = ({
  onCompleteAccountSetup,
}: {
  onCompleteAccountSetup: () => void;
}) => {
  const { formatMessage } = useIntl();

  const {
    isLoading,
    mobilePhoneNumber,
    homePhoneNumber,
    email,
    isTextNotificationsEnabled,
  } = useAccountSetupFormDefaultValues();

  const { userFullName } = useUserName();

  const methods = useForm({
    defaultValues: {
      mobilePhoneNumber,
      homePhoneNumber,
      email,
      isTextNotificationsEnabled,
    },
  });

  const { isDirty } = methods.formState;

  const { updateAccountSetup } = useUpdateAccountSetup();
  const isBelowTablet = useIsBelowTablet();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.ONBOARDING,
    screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
  });

  const isMobilePhoneNumberEmpty = mobilePhoneNumber.length === 0;
  const [checkboxDisabled, setCheckboxDisabled] = useState<boolean>(
    isMobilePhoneNumberEmpty,
  );

  const [
    isTextCommunicationTermsModalOpen,
    setIsTextCommunicationTermsModalOpen,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (isMobilePhoneNumberEmpty)
      methods.setValue('isTextNotificationsEnabled', false);
  }, [isMobilePhoneNumberEmpty, methods]);

  const handleCheckboxChange = () => {
    const checked = methods.getValues('isTextNotificationsEnabled');
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
      detail: 'text notification toggle',
      toggle_status: checked ? 'on' : 'off',
    });

    if (checked) {
      setIsTextCommunicationTermsModalOpen(true);
    }
  };

  const handleMobilePhoneNumberChange = () => {
    if (methods.getValues('mobilePhoneNumber') !== mobilePhoneNumber) {
      methods.setValue('isTextNotificationsEnabled', false);
    }
    setCheckboxDisabled(methods.getValues('mobilePhoneNumber').length === 0);
  };

  const handleFormSubmit = async (values: AccountSetupInputs) => {
    if (!isDirty) {
      onCompleteAccountSetup();
    }

    try {
      await updateAccountSetup(values);
      onCompleteAccountSetup();
    } catch (error) {
      let errorMessage;
      if (error instanceof Error) {
        captureAppError(error, {
          ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
          errorName: 'Error saving Account setup form',
          context: {
            Context: {
              description: formatMessage({ id: 'STR_ACCOUNT_SETUP' }),
            },
          },
        });
        if (error.message) {
          errorMessage = JSON.parse(error.message);
        }
      }
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        text:
          errorMessage?.info?.reason ||
          formatMessage({ id: 'STR_SOMETHING_WENT_WRONG_TRY_AGAIN' }),
      });
    }
  };

  if (isLoading || isTextNotificationsEnabled === undefined)
    return <LoadingIndicator />;

  return (
    <BasePage
      isPrivatePage={false}
      css={{
        padding: isBelowTablet ? '$none' : '$twoAndHalf',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StackItem
        css={{
          ...(isBelowTablet && { height: '100%' }),
          maxWidth: isBelowTablet ? '100%' : ACCOUNT_SETUP_CONTENT_MAX_WIDTH,
          background: '$surfaceCardSecondary',
          boxShadow: '$card',
        }}
      >
        <StackLayout
          spacing="$two"
          css={{
            padding: '$two',
            paddingBlockEnd: '$none',
          }}
        >
          <StackItem horizontalAlignment="center" css={{ alignSelf: 'center' }}>
            <HeadingText size="xl" level="2" as="h1">
              {formatMessage({ id: 'STR_ACCOUNT_SETUP_HEADING' })}
            </HeadingText>
            <ParagraphText
              css={{ textAlign: 'center', marginBlockStart: '$half' }}
            >
              {formatMessage({ id: 'STR_ACCOUNT_SETUP_SUBHEADING' })}
            </ParagraphText>
          </StackItem>
          <StackItem horizontalAlignment="center" css={{ alignSelf: 'center' }}>
            <ProfilePictureForm />
            <HeadingText
              size="lg"
              level="2"
              as="h2"
              css={{ marginBlockStart: '$half', textAlign: 'center' }}
            >
              {`[${userFullName}]`}
            </HeadingText>
          </StackItem>
        </StackLayout>
        <FormProvider
          formMethods={methods}
          onSubmit={handleFormSubmit}
          css={{
            display: 'grid',
          }}
        >
          <StackItem
            css={{
              gap: '$one',
              padding: '$two',
            }}
          >
            <RHFTextInput
              id="mobilePhoneNumber"
              type="tel"
              placeholder={formatMessage({ id: 'STR_PHONE_PLACEHOLDER' })}
              label={formatMessage({ id: 'STR_MOBILE_PHONE_NUMBER' })}
              {...(isTextNotificationsEnabled && {
                hint: formatMessage({ id: 'STR_MOBILE_NUMBER_CHANGE_HINT' }),
              })}
              {...(checkboxDisabled && {
                hint: formatMessage({ id: 'STR_MOBILE_NUMBER_REQUIRED' }),
              })}
              registerOptions={phoneNumberValidation(
                formatMessage({ id: 'STR_PHONE_VALIDATION_ERROR' }),
              )}
              onChange={handleMobilePhoneNumberChange}
            />
            <RHFTextInput
              id="homePhoneNumber"
              type="tel"
              placeholder={formatMessage({ id: 'STR_PHONE_PLACEHOLDER' })}
              label={formatMessage({ id: 'STR_HOME_PHONE_NUMBER' })}
              required
              registerOptions={phoneNumberValidation(
                formatMessage({ id: 'STR_PHONE_VALIDATION_ERROR' }),
              )}
            />
            <RHFTextInput
              id="email"
              inputMode="email"
              placeholder={formatMessage({ id: 'STR_EMAIL_PLACEHOLDER' })}
              label={formatMessage({ id: 'STR_EMAIL_ADDRESS' })}
              required
              registerOptions={emailValidation(
                formatMessage({ id: 'STR_EMAIL_VALIDATION_ERROR' }),
              )}
            />
            <RHFCheckbox
              disabled={checkboxDisabled}
              id="isTextNotificationsEnabled"
              name="isTextNotificationsEnabled"
              label={formatMessage({
                id: 'STR_TEXT_COMMUNICATION_OPT_IN',
              })}
              onChange={handleCheckboxChange}
            />
          </StackItem>

          <StackItem
            css={{
              borderColor: '$decorativeBrandPrimaryBrightest',
              borderStyle: 'solid',
              borderBlockStartWidth: 'thin',
              minHeight: 81,
              paddingInline: '$oneAndHalf',
            }}
            verticalAlignment="center"
            horizontalAlignment="end"
          >
            <Button
              type="submit"
              width={isBelowTablet ? 'fillContainer' : 'hugContents'}
            >
              {formatMessage({ id: 'STR_CONTINUE' })}
            </Button>
          </StackItem>
        </FormProvider>
      </StackItem>
      <TextCommunicationTermsModal
        open={isTextCommunicationTermsModalOpen}
        closeModal={(isTextCommunicationTermsAccepted: boolean) => {
          setIsTextCommunicationTermsModalOpen(false);
          methods.setValue(
            'isTextNotificationsEnabled',
            isTextCommunicationTermsAccepted,
          );
        }}
      />
    </BasePage>
  );
};
