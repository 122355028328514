import { useState, type ReactNode } from 'react';
import DOMPurify from 'dompurify';
import { useCombobox, type UseComboboxSelectedItemChange } from 'downshift';
import {
  Box,
  TextInput,
  UtilityText,
  Popover,
  InputLabel,
  GDSPopoverPrimitive,
  Icon,
} from '@leagueplatform/genesis-core';
import { useResizePopover } from 'hooks/use-resize-popover.hook';
import { AutocompleteOptions, AutocompleteOption } from 'common/types/types';
import { PopoverContent } from '../ui-elements/popover-content.component';

export type AutocompleteInputProps = {
  label: string;
  options: AutocompleteOptions;
  onInputValueChange: (value: string) => void;
  onSelectedItemChange: (
    item: UseComboboxSelectedItemChange<AutocompleteOption>['selectedItem'],
  ) => void;
  value: string;
  hasError: boolean;
  required?: boolean;
  placeholder?: string;
  menuFooter?: ReactNode;
};

const highlightGoogleMatches = (
  text: string,
  matches: { length: number; offset: number }[],
) => {
  let highlightedText = '';
  let lastIndex = 0;

  matches.forEach((match) => {
    const { length, offset } = match;
    // Append the text before the match
    highlightedText += text.slice(lastIndex, offset);
    // Append the highlighted match
    highlightedText += `<b>${text.substring(offset, offset + length)}</b>`;
    // Update the last index to the end of the current match
    lastIndex = offset + length;
  });

  // Append any remaining text after the last match
  highlightedText += text.slice(lastIndex);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(highlightedText, {
          FORCE_BODY: true,
        }),
      }}
    />
  );
};

const highlightRegexMatch = (string: string, substring: string) => {
  const escapedSubstring = substring.replace('\\', '');
  const regex = new RegExp(escapedSubstring, 'gi');
  const match = string.match(regex)?.[0];

  return match ? (
    <span
      dangerouslySetInnerHTML={{
        __html: string.replace(
          regex,
          DOMPurify.sanitize(`<b>${match}</b>`, {
            FORCE_BODY: true,
          }),
        ),
      }}
    />
  ) : (
    string
  );
};

export const AutocompleteInput = ({
  label,
  options: items,
  required,
  placeholder,
  onInputValueChange,
  onSelectedItemChange,
  value,
  hasError,
  menuFooter,
}: AutocompleteInputProps) => {
  const { anchorRef, menuWidth } = useResizePopover<HTMLLabelElement>();

  // Downshift
  const [currentValue, setCurrentValue] = useState(value || '');
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setCurrentValue(inputValue);
      onInputValueChange(inputValue);
    },
    items,
    itemToString: (item) => item?.value || '',
    onSelectedItemChange: (item) => onSelectedItemChange(item.selectedItem),
  });

  return (
    <Box>
      <Popover.Root open>
        <InputLabel
          {...getLabelProps()}
          required={required}
          css={{ marginBlockEnd: '$half' }}
          ref={anchorRef}
        >
          {label}
        </InputLabel>
        <GDSPopoverPrimitive.Anchor>
          <TextInput
            leadingContent={<Icon icon="interfaceSearch" />}
            {...(placeholder && { placeholder })}
            {...(hasError && { inputStatus: 'error' })}
            {...getInputProps()}
            value={currentValue}
            clearable
            onClear={() => {
              setCurrentValue('');
              onInputValueChange('');
            }}
          />
        </GDSPopoverPrimitive.Anchor>
        <PopoverContent
          isOpen={isOpen && items.length > 0}
          menuWidth={menuWidth}
        >
          {/* False alarm caused by portal use: https://github.com/downshift-js/downshift/issues/1272 */}
          <Box as="ul" {...getMenuProps({}, { suppressRefError: true })}>
            {items.map((item, index) => (
              <UtilityText
                as="li"
                key={item.key}
                css={{
                  paddingInline: '$half',
                  paddingBlock: 10,
                  borderBlockEnd:
                    index === items.length - 1
                      ? 'none'
                      : '$borderWidths$thin solid $colors$onSurfaceBorderSubdued',
                  b: {
                    fontWeight: 'bold',
                  },
                  ...(highlightedIndex === index && {
                    backgroundColor: '$inputBackgroundHovered',
                  }),
                }}
                {...getItemProps({ item, index })}
              >
                {item.matches
                  ? highlightGoogleMatches(item.value, item.matches)
                  : highlightRegexMatch(item.value, currentValue)}
              </UtilityText>
            ))}
          </Box>
          {menuFooter || null}
        </PopoverContent>
      </Popover.Root>
    </Box>
  );
};
