import { useMemo } from 'react';
import { Box, UtilityText } from '@leagueplatform/genesis-core';
import { useFormContext } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { removeTimestamp } from 'common/utils/date.util';
import type { AddressFormSectionInputs } from 'common/types/account-info-form-types';
import type { AutocompleteOption } from 'common/types/types';
import GOOGLE_LOGO from 'assets/google_logo.png';
import { RHFTextInput } from 'components/form-elements/rhf-text-input.component';
import { RHFSelectInput } from 'components/form-elements/rhf-select-input.component';
import { RHFAutocompleteInput } from 'components/form-elements/rhf-autocomplete-input.component';
import { useGooglePlacesAutocomplete } from 'hooks/use-google-places-autocomplete/use-google-places-autocomplete.hook';
import { useMonthsAhead } from 'hooks/use-months-ahead/use-months-ahead.hook';
import {
  ADDRESS_FORM_ELEMENT_IDS,
  ADDRESS_TYPES,
} from 'common/constants/account-info';

type AddressFormProps = {
  addressType: string;
  defaultValues?: AddressFormSectionInputs;
};
// Check if fields with a specific substring are dirty
const hasDirtyFields = (
  dirtyFields: { [key: string]: string },
  substring: string,
) => {
  const regex = new RegExp(substring, 'i');
  return Object.keys(dirtyFields).some((item) => regex.test(item));
};

export const AddressForm = ({
  addressType,
  defaultValues,
}: AddressFormProps) => {
  const { formatMessage } = useIntl();

  // Initialize React Hook Form
  const {
    getValues,
    formState: { dirtyFields },
  } = useFormContext();

  // Add mailing_ or residential_ to form field ids
  const PREFIXED_ADDRESS_IDS = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(ADDRESS_FORM_ELEMENT_IDS).map(([key, value]) => [
          key,
          `${addressType}_${value}`,
        ]),
      ),
    [addressType],
  );

  // Get 2 future months for effective date dropdown
  const residentialAddressEffectiveDateOptions = useMonthsAhead(2, true);
  const residentialStateOriginal = defaultValues?.residential_state;

  const {
    predictionResults,
    onInputChange,
    handleSuggestionClick,
    mapAttributionElementRef,
  } = useGooglePlacesAutocomplete({
    displayAddressId: PREFIXED_ADDRESS_IDS.DISPLAY_ADDRESS,
    streetAddressInputId: PREFIXED_ADDRESS_IDS.STREET_ADDRESS,
    cityInputId: PREFIXED_ADDRESS_IDS.CITY,
    stateInputId: PREFIXED_ADDRESS_IDS.STATE,
    zipCodeInputId: PREFIXED_ADDRESS_IDS.ZIP_CODE,
  });

  return (
    <>
      <RHFAutocompleteInput
        options={predictionResults.map(
          ({ place_id, description, matched_substrings }) => ({
            key: place_id,
            value: description,
            matches: matched_substrings,
          }),
        )}
        onInputValueChange={onInputChange}
        label={formatMessage({ id: 'STR_STREET_ADDRESS' })}
        placeholder={formatMessage({ id: 'STR_ADDRESS_SEARCH_PLACEHOLDER' })}
        required
        id={PREFIXED_ADDRESS_IDS.DISPLAY_ADDRESS}
        onSelectedItemChange={(item: AutocompleteOption) =>
          handleSuggestionClick(item.key)
        }
        // Validate same state for residential address
        {...(addressType === ADDRESS_TYPES.RESIDENTIAL && {
          validationRules: {
            validate: {
              sameState: () => {
                const { residential_state: residentialStateInput } =
                  getValues();
                // Validate only if the state exists; otherwise valid by default
                if (residentialStateOriginal) {
                  return (
                    (residentialStateInput &&
                      residentialStateInput === residentialStateOriginal) ||
                    formatMessage(
                      { id: 'STR_STATE_ERROR' },
                      { stateCode: residentialStateOriginal },
                    )
                  );
                }
                return true;
              },
            },
          },
        })}
        menuFooter={
          <Box css={{ img: { marginInlineStart: 2 } }}>
            <UtilityText
              size="xs"
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {formatMessage(
                { id: 'STR_POWERED_BY_GOOGLE' },
                { google: <img height="14" src={GOOGLE_LOGO} alt="Google" /> },
              )}
            </UtilityText>
          </Box>
        }
      />

      <RHFTextInput
        id={PREFIXED_ADDRESS_IDS.STREET_ADDRESS_2}
        label={formatMessage({ id: 'STR_STREET_ADDRESS_2' })}
      />

      {addressType === ADDRESS_TYPES.RESIDENTIAL &&
        hasDirtyFields(dirtyFields, ADDRESS_TYPES.RESIDENTIAL) && (
          <RHFSelectInput
            id={PREFIXED_ADDRESS_IDS.EFFECTIVE_DATE}
            label={formatMessage({ id: 'STR_EFFECTIVE_DATE' })}
            options={residentialAddressEffectiveDateOptions}
            required
          />
        )}

      {addressType === ADDRESS_TYPES.MAILING &&
        hasDirtyFields(dirtyFields, ADDRESS_TYPES.MAILING) && (
          <RHFTextInput
            // Allow only future dates.
            min={removeTimestamp(new Date().toISOString())}
            type="date"
            id={PREFIXED_ADDRESS_IDS.EFFECTIVE_DATE}
            label={formatMessage({ id: 'STR_EFFECTIVE_DATE' })}
            required
          />
        )}
      <div ref={mapAttributionElementRef} />
    </>
  );
};
