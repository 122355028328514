import { useRef, useEffect, useState, useCallback } from 'react';

// ---- Match popover menu to its anchor in width
export const useResizePopover = <T extends HTMLElement>() => {
  const anchorRef = useRef<T>(null);
  const [menuWidth, setMenuWidth] = useState(anchorRef?.current?.clientWidth);

  const onResizeAnchor = useCallback(() => {
    setMenuWidth(anchorRef?.current?.clientWidth);
  }, [anchorRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: currentAnchorRef } = anchorRef;
    if (currentAnchorRef !== null) {
      const resizeObserver = new ResizeObserver(onResizeAnchor);
      resizeObserver.observe(currentAnchorRef);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [anchorRef, onResizeAnchor]);

  return { anchorRef, menuWidth };
};
