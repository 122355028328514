import {
  Card,
  HeadingText,
  ParagraphText,
  TextAction,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { type MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import type { PriorAuthorizationStatuses } from 'common/types/types';
import { AuthorizationStatusBadge } from 'components/prior-authorization-status/authorization-status-badge.component';
import { Link } from '@leagueplatform/routing';

type AuthorizationCardNodeProperties = {
  heading: string;
  body: string;
  status: PriorAuthorizationStatuses;
};

type AuthorizationCardNodeActions = {
  onClick: MasonryDeeplinkAction;
};

type AuthorizationCardNode = MasonryEngineNode<
  'authorizationCard',
  AuthorizationCardNodeProperties,
  AuthorizationCardNodeActions
>;

type AuthorizationCardProps =
  MasonryEngineNodeRendererProps<AuthorizationCardNode>;

export const AuthorizationCard = ({
  heading,
  body,
  status,
  onClick,
}: AuthorizationCardProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Card.Flat
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: 'transparent',
        width: '100%',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
        css={{
          flexDirection: 'column',
          border: 'none !important',
          paddingInline: '$oneAndHalf',
          paddingBlock: '$oneAndHalf',
          gap: '$quarter',
          backgroundColor: '$surfaceCardSecondary',
        }}
      >
        <HeadingText
          level="3"
          size="sm"
          css={{
            typography: '$subtitleOne',
            marginBlockEnd: '$none',
          }}
        >
          <TextAction
            as={Link}
            to={onClick?.payload.url}
            ref={primaryActionRef}
            css={{
              display: 'block',
              width: 'fit-content',
            }}
            onClick={(event: React.MouseEvent) => {
              if (onClick) {
                invokeAction({
                  event,
                  ...onClick,
                });
              }
            }}
          >
            {heading}
          </TextAction>
        </HeadingText>
        <ParagraphText
          css={{
            typography: '$bodyTwo',
            marginBlockEnd: '$none',
          }}
        >
          {body}
        </ParagraphText>
        <AuthorizationStatusBadge status={status} />
      </Card.ClickArea>
    </Card.Flat>
  );
};
