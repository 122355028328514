import { useRef, useEffect, useCallback, useState } from 'react';
import { type GDSColor, Box } from '@leagueplatform/genesis-core';
import type {
  MasonryEngineNodeRendererProps,
  MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { PageHeader } from 'components/page-header/page-header.component';
import { AuthorizationStatusBadge } from 'components/prior-authorization-status/authorization-status-badge.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import type { PriorAuthorizationStatuses } from 'common/types/types';
import { PAGE_NAVIGATION_WIDTH } from 'common/constants/ui';

type AuthorizationDetailsHeaderProperties = {
  heading: string;
  status: PriorAuthorizationStatuses;
};

type AuthorizationDetailsHeaderNode = MasonryEngineNode<
  'authorizationDetailsHeader',
  AuthorizationDetailsHeaderProperties
>;

type AuthorizationCardProps =
  MasonryEngineNodeRendererProps<AuthorizationDetailsHeaderNode>;

const HeaderBackgroundTokenMap: Record<PriorAuthorizationStatuses, GDSColor> = {
  approved: '$successBackgroundSubdued',
  inProgress: '$warningBackgroundSubdued',
  partiallyApproved: '$highlightBackgroundSubdued',
  denied: '$criticalBackgroundSubdued',
};

export const AuthorizationDetailsHeader = ({
  heading,
  status,
}: AuthorizationCardProps) => {
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const isBelowTablet = useIsBelowTablet();
  const [responsiveBannerHeight, setResponsiveBannerHeight] = useState<any>();

  const onResize = useCallback(() => {
    setResponsiveBannerHeight(pageHeaderRef?.current?.offsetHeight);
  }, [pageHeaderRef]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: currentRef } = pageHeaderRef;
    if (currentRef !== null) {
      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(currentRef);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [pageHeaderRef, onResize]);

  return (
    <Box css={{ height: isBelowTablet ? responsiveBannerHeight : 140 }}>
      <Box
        ref={pageHeaderRef}
        css={{ position: 'absolute', width: '100%', left: 0 }}
      >
        <PageHeader
          text={heading}
          historyGoBack
          backgroundColor={HeaderBackgroundTokenMap[status]}
          maxWidth={PAGE_NAVIGATION_WIDTH}
          subHeading={
            <Box css={{ position: 'relative' }}>
              <Box
                css={
                  isBelowTablet
                    ? {
                        position: 'relative',
                        marginBlockStart: '$twoAndHalf',
                      }
                    : {
                        position: 'absolute',
                        top: '$half',
                      }
                }
              >
                <AuthorizationStatusBadge status={status} showLight />
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};
