import { PageHeader } from 'components/page-header/page-header.component';
import type {
  MasonryEngineNode,
  MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';

export type PrescriptionSectionHeaderNodeProperties = {
  heading: string;
};

export type PrescriptionSectionHeaderNode = MasonryEngineNode<
  'sectionHeader',
  PrescriptionSectionHeaderNodeProperties
>;

export type PrescriptionSectionHeaderProps =
  MasonryEngineNodeRendererProps<PrescriptionSectionHeaderNode>;

export const PrescriptionSectionHeader = ({
  heading,
}: PrescriptionSectionHeaderProps) => (
  <PageHeader text={heading} historyGoBack />
);
