import React from 'react';
import {
  Box,
  useClickableCard,
  Card,
  HeadingText,
  TextAction,
  StackLayout,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import {
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
  type MasonryEngineNodeRendererProps,
} from '@leagueplatform/masonry-engine';
import { type MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import { Link } from '@leagueplatform/routing';

export type PrescriptionSmallCardNodeProperties = {
  heading: string;
  body: string;
};

type PrescriptionSmallCardNodeActions = {
  onClick?: MasonryDeeplinkAction;
};

export type PrescriptionSmallCardNode = MasonryEngineNode<
  'smallCard',
  PrescriptionSmallCardNodeProperties,
  PrescriptionSmallCardNodeActions
>;

export type PrescriptionSmallCardProps =
  MasonryEngineNodeRendererProps<PrescriptionSmallCardNode>;

export const PrescriptionSmallCard = ({
  heading,
  body,
  onClick,
}: PrescriptionSmallCardProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Box>
      <Card.Flat
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          boxShadow: '0px 2px 6px 0px #0000001F',
          borderRadius: '8px',
          border: 'none',
          backgroundColor: 'transparent',
          width: '100%',
        }}
      >
        <Card.ClickArea
          ref={clickAreaRef}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
          imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
          css={{
            flexDirection: 'column',
            border: 'none !important',
            paddingInline: '$oneAndHalf',
            paddingBlock: '$oneAndHalf',
            gap: '$quarter',
            backgroundColor: '$surfaceCardSecondary',
          }}
        >
          <StackLayout spacing="$quarter">
            <HeadingText level="3" size="sm">
              <TextAction
                as={Link}
                to={(onClick as MasonryDeeplinkAction)?.payload.url}
                ref={primaryActionRef}
                css={{
                  display: 'block',
                  width: 'fit-content',
                }}
                onClick={(event: React.MouseEvent) => {
                  if (onClick) {
                    invokeAction({
                      event,
                      ...onClick,
                    });
                  }
                }}
              >
                {heading}
              </TextAction>
            </HeadingText>
            <ParagraphText>{body}</ParagraphText>
          </StackLayout>
        </Card.ClickArea>
      </Card.Flat>
    </Box>
  );
};
