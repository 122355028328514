import {
  Card,
  HeadingText,
  TextAction,
  useClickableCard,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';
import {
  type MasonryEngineNodeRendererProps,
  useMasonryEngineActionEmitter,
  type MasonryEngineNode,
} from '@leagueplatform/masonry-engine';
import { type MasonryDeeplinkAction } from '@leagueplatform/masonry-renderers';
import { Link } from '@leagueplatform/routing';
import { RefillScoreStatusBadge } from 'components/refill-score/refill-score-status-badge.component';
import type { RefillScoreStatusColors } from 'common/types/types';

type RefillScoreCardNodeProperties = {
  heading: string;
  body: string;
  color: RefillScoreStatusColors;
  image: string;
  label: string;
};

type RefillScoreCardNodeActions = {
  onClick: MasonryDeeplinkAction;
};

type RefillScoreCardNode = MasonryEngineNode<
  'simpleBadgeCard',
  RefillScoreCardNodeProperties,
  RefillScoreCardNodeActions
>;

type RefillScoreCardProps = MasonryEngineNodeRendererProps<RefillScoreCardNode>;

export const RefillScoreCard = ({
  color,
  heading,
  image,
  label,
  onClick,
}: RefillScoreCardProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  const invokeAction = useMasonryEngineActionEmitter();

  return (
    <Card.Flat
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: 'transparent',
        width: '100%',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
        css={{
          flexDirection: 'column',
          border: 'none !important',
          paddingInline: '$oneAndHalf',
          paddingBlock: '$oneAndHalf',
          gap: '$quarter',
          backgroundColor: '$surfaceCardSecondary',
        }}
      >
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          verticalAlignment="center"
        >
          <StackItem css={{ gap: '$threeQuarters' }}>
            <HeadingText
              level="3"
              size="sm"
              css={{
                typography: '$subtitleOne',
                marginBlockEnd: '$none',
              }}
            >
              <TextAction
                as={Link}
                to={onClick?.payload.url}
                ref={primaryActionRef}
                css={{
                  display: 'block',
                  width: 'fit-content',
                }}
                onClick={(event: React.MouseEvent) => {
                  if (onClick) {
                    invokeAction({
                      event,
                      ...onClick,
                    });
                  }
                }}
              >
                {heading}
              </TextAction>
            </HeadingText>
            <RefillScoreStatusBadge label={label} color={color} />
          </StackItem>
          <StackItem>
            <img src={image} alt="" />
          </StackItem>
        </StackLayout>
      </Card.ClickArea>
    </Card.Flat>
  );
};
