// Based on this example: https://github.com/visgl/react-google-maps/blob/632838bdbd7dc60a6e0e3751c9babcb77fa91f6b/examples/autocomplete/src/autocomplete-custom.tsx
import { useEffect, useState, useCallback, useRef } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useFormContext } from '@leagueplatform/web-common';

type UseGooglePlacesAutocompleteProps = {
  displayAddressId: string;
  streetAddressInputId: string;
  cityInputId: string;
  zipCodeInputId: string;
  stateInputId: string;
};

const formatAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): Record<
  google.maps.GeocoderAddressComponent['types'][number],
  string | undefined
> =>
  addressComponents?.reduce((componentAccumulator, addressComponent) => {
    const addressComponentTypes = addressComponent.types.reduce(
      (typesAccumulator, addressComponentType) => ({
        ...typesAccumulator,
        [addressComponentType]:
          addressComponentType === 'administrative_area_level_1'
            ? addressComponent.short_name
            : addressComponent.long_name,
      }),
      {},
    );
    return { ...componentAccumulator, ...addressComponentTypes };
  }, {});

export const useGooglePlacesAutocomplete = (
  ids: UseGooglePlacesAutocompleteProps,
) => {
  const {
    displayAddressId,
    streetAddressInputId,
    cityInputId,
    stateInputId,
    zipCodeInputId,
  } = ids;

  const { setValue } = useFormContext();

  // Google Places
  const map = useMap();
  const places = useMapsLibrary('places');
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);
  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);
  const mapAttributionElementRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!places || !mapAttributionElementRef.current) return;
    setAutocompleteService(new places.AutocompleteService());

    setPlacesService(
      new places.PlacesService(mapAttributionElementRef.current),
    );
    setSessionToken(new places.AutocompleteSessionToken());
  }, [map, places]);

  const fetchPredictions = useCallback(
    async (value: string) => {
      if (!autocompleteService || !value) {
        setPredictionResults([]);
        return;
      }
      setInputValue(value);
      const request = {
        input: value,
        sessionToken,
        componentRestrictions: { country: 'us' },
      };
      const response = await autocompleteService.getPlacePredictions(request);
      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken],
  );

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ['address_components'],
        types: ['street_address'],
        sessionToken,
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null,
      ) => {
        setPredictionResults([]);
        setSessionToken(new places.AutocompleteSessionToken());
        if (placeDetails?.address_components) {
          const addressComponentsObj = formatAddressComponents(
            placeDetails?.address_components,
          );
          const {
            street_number: streetNumber = ' ',
            route = ' ',
            locality,
            sublocality_level_1: sublocalityLevel1,
            administrative_area_level_1: administrativeAreaLevel1,
            postal_code: postalCode,
          } = addressComponentsObj;

          // Street Address
          setValue(streetAddressInputId, `${streetNumber} ${route}`.trim());
          // City
          setValue(cityInputId, locality || sublocalityLevel1);
          // Zip Code
          setValue(zipCodeInputId, postalCode);
          // State
          setValue(stateInputId, administrativeAreaLevel1);
          // Display address (to trigger validation)
          setValue(displayAddressId, inputValue, {
            shouldValidate: true,
          });
        }
        return null;
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [
      places,
      placesService,
      sessionToken,
      displayAddressId,
      streetAddressInputId,
      cityInputId,
      stateInputId,
      zipCodeInputId,
      inputValue,
      setValue,
    ],
  );

  return {
    predictionResults,
    onInputChange: fetchPredictions,
    handleSuggestionClick,
    mapAttributionElementRef,
  };
};
