import { MasonryDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import {
  MASONRY_NAMESPACES,
  MASONRY_WIDGET_TYPES,
} from 'common/constants/masonry';
import { StackLayout } from '@leagueplatform/genesis-core';

// TODO remove once all renderers are complete
const PlaceholderComponent = (props: any) => {
  const { nodeId } = props;
  console.log(nodeId, props);
  return <StackLayout>{nodeId}</StackLayout>;
};

export const initContactScanRegistry = () => {
  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.CONTACT_SCAN,
    MASONRY_WIDGET_TYPES.SECTION_HEADER,
    PlaceholderComponent,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.CONTACT_SCAN,
    MASONRY_WIDGET_TYPES.DESCRIPTIVE_SECTION_WITH_IMAGE,
    PlaceholderComponent,
  );

  MasonryDriverNodeRenderersRegistry.registerRenderer(
    MASONRY_NAMESPACES.CONTACT_SCAN,
    MASONRY_WIDGET_TYPES.HEADING_WITH_ICON,
    PlaceholderComponent,
  );
};
